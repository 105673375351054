.catalog-settings-wrapper{
  margin:20px 0;
}

.catalog-settings{
  padding:10px;
  background: @search-form-bg;
  font-size:12px;

  .filter-box{
    display:inline-block;
    margin:0 10px;
    label{
      margin-bottom:0px;

    }
  }

}