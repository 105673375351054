@import "bootstrap/bootstrap";
@import (inline) "ion.rangeSlider.css";
@import (inline) "ion.rangeSlider.skinFlat.css";
@import "overrides.less";
@import "variables.less";
@import "header.less";
@import "main-page.less";
@import "footer.less";
@import "category-tree.less";
@import "product-list.less";
@import "product.less";
@import "property-table.less";
@import "search-form.less";
@import "catalog.less";
@import "news.less";
@import "paginator.less";
@import "downloads-list.less";
@import "latest-news.less";
@import "popups.less";
@import "mobile.less";
@import (inline) "../../../public/fonts/Roboto/stylesheet.css";

@btn-font-weight: 300;
@font-family-sans-serif: "Roboto", Helvetica, Arial, sans-serif;

body, label, .checkbox label {
  font-weight: 300;
}

html, body{
  height:100%;
  min-height:100%;
}

.wrapper{
  min-height:100%;
  position:relative;
}

main{
  padding-bottom:20px;
}

.page-main{
  .make-md-column(9);
  .make-lg-column(9);
}

.page-additional{
  .make-md-column(3);
  .make-lg-column(3);
}

.tbl{
  display:table;
  width:100%;
}

.tbl-row{
  display:table-row;
}

.tbl-cell{
  display:table-cell;
  vertical-align:top;
}

.required-mark{
  margin-left:2px;
  color:@danger-color;
}

.square-image(@size){
  display:inline-block;
  height:@size;
  width:@size;
  overflow:hidden;
  border:2px solid #ddd;
  white-space: nowrap;
  font-size:0;

  &:before{
    display:inline-block;
    height:100%;
    width:0;
    vertical-align:middle;
    content:'';
  }

  img{
    vertical-align: middle;
    width:100%;
  }
}

.arrow-right(@color, @width, @height){
  width: 0;
  height: 0;
  border-top: @height solid transparent;
  border-bottom: @height solid transparent;

  border-left:@width solid @color;
}



