#tthSearchButton{
  width:100%;
  text-transform:uppercase;
  font-weight:bold;
  margin-top:20px;
}

.search-form{
  padding:10px;
  border:1px solid #ddd;
  position:relative;
  background: @search-form-bg;
  /*.hidden-sm();
  .hidden-xs();*/

  .search-helper{
    @search-helper-color:#fff;
    @search-helper-height:45px;
    @corner-size: @search-helper-height/sqrt(2);
    position:absolute;
    width:auto;
    left:105%;
    display:none;
    min-width:270px;
    height:@search-helper-height;
    background:@search-helper-color;
    border:2px solid #ddd;

    z-index:5;
    opacity:0;
    box-shadow:0 0 10px 5px rgba(0,0,0,0.5);


    &.enabled{
      display:block;
      opacity:1;
    }

    .helper-main{
      position:relative;
      z-index:2;
      background:@search-helper-color;
      padding: 5px;
    }

    .helper-info{
      margin-top:5px;
      display:inline-block;
    }

    &:before{
      content:' ';
      position:absolute;
      top:0;
      left:0;
      z-index:1;
      box-shadow:-5px 5px 10px 0 rgba(0,0,0,0.5);
      height:@corner-size;
      width:@corner-size;
      margin-left:-(@corner-size / 2) - 1;
      margin-top:4px;
      background:@search-helper-color;
      transform: rotate(45deg);
      vertical-align:middle;
      border-left:2px solid #ddd;
      border-bottom:2px solid #ddd;
    }

    .show-search-results{
      .btn();
      .btn-default();
      .btn-sm();
      .pull-right();
      vertical-align: middle;
    }
  }

  .section-wrapper{
  }


  .search-section{
    margin-bottom:10px;
    font-size:12px;
  }

  .multiple-table{
    display: table;

    .column{
      display:table-cell;
      padding:5px;
    }
  }

  .multiple-option-wrapper{
    white-space: nowrap;
    margin-bottom:10px;
    label{
      white-space:normal;
      vertical-align:middle;
      margin:0;
      width: 95%;
    }
    input{
      vertical-align:middle;
      margin:0;
    }

  }

  .diapason-input {
    .form-control();
    .input-sm();
    display:inline-block;
    width: 100px;
  }

  .slider-wrapper{
    padding:10px 0;
  }
}

