.category-tree{
  margin: 20px 0;
  padding-left:10px;
  .category{
    display:none;
    a{
      color:#333;
    }
    &.category-level-1, &.category-level-2{
      display:block;

    }
    &.category-level-1 > a{
      font-size:1.1em;
      //color:#666;
      font-weight:bold;
    }
  }
}



