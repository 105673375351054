.paginator{
  padding: 20px 10px;
  border-top:1px solid #ddd;
  text-align:right;

  a{
    text-decoration: none;
  }

  .paginator-item{
    padding:3px;
  }
}