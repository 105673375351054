.news-list{
  h2{
    margin-top:0;
    font-size:1.6em;
  }

  .tbl-cell{
    padding:10px;
  }

  .news-announce{
    line-height:1.4em;
    max-height: 4*1.4em;
    overflow:hidden;
  }

  .news-date{
    color:#bbb;
    font-size:0.9em;
  }
}


.news-list-image-link{
  .square-image(150px);
}