
/* Custom code */

#catalogButton {
  width: 100%;
  text-transform: uppercase;
  font-weight: bold;
  margin: 20px 0 10px;
}

@media only screen and (min-width: @screen-sm-min) {
  .mb-visible {
    display: none;
  }
}

table.adaptive_mob {
  width: 100%; border: 0px; border-collapse: collapse;
}
table.adaptive_mob thead {
  font-weight: bold;
}
table.adaptive_mob td {
  padding: 0.6rem 1rem; border-bottom: 1px solid #e8e9eb;
}
@media screen and (max-width: @screen-xs-max) {
  table.adaptive_mob thead {
    display: none;
  }
  table.adaptive_mob tr {
    display: block;
    margin-bottom: 1rem;
    border-bottom: 2px solid #e8e9eb;
  }
  table.adaptive_mob td {
    display: block;
    text-align: right;
  }
  table.adaptive_mob td:before {
    content: attr(title);
    float: left;
    font-weight: bold;
  }
}

@media only screen and (max-width: @screen-xs-max) {
  .mb-hidden {
    display: none;
  }

  /* Header */
  .header-top{
    display: none;
  }
  .main-search-wrapper{
    display: none;
  }


  /* Catalog */

  .product-list .tbl-row {
    display: flex;
    justify-content: flex-end;
    flex-direction: column-reverse;
  }

  .product-list > article.tbl-row {
    position: relative;
    height: 300px;
    margin-bottom: 20px;
  }

  .product-list .tbl-cell {
    display: inline-block;
  }

  .product-list .price-cell {
    left: 55%;
    top: 25%;
    position: absolute;
  }

  .product-list .product-small-descr {
    height: 4.2em;
    overflow: hidden;
    position: absolute;
    bottom: 0;
  }

  .breadcrumb {
    float: none;
  }

  .product.page-main h1 {
    font-size: 25px;
  }

  .product-main .tbl-cell {
    display: block;
  }

  .product-main .product-image {
    width: 100%;
  }

  .product-image-wrapper {
    height: 100%;
    width: 100%;
  }

  .product-main .prices-cell {
    margin-top: 10px;
  }

  .product-info-sections-list .section-switch {
    width: auto;
  }

  .product-main .banner-cell {
    text-align: left;
  }

  .product iframe {
    max-width: 100%;
  }

  /* News */

  .news-list .tbl-cell:nth-child(1) {
    display: none;
  }

  .news-list .tbl-cell {
    padding-left: 0;
  }

  .news-wrapper h1 {
    font-size: 25px;
  }

  .news-wrapper img {
    max-width: 100%;
  }

  /* Popup */

  .common-popup.enabled {
    max-width: 300px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}