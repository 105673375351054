.product-list{
  h2{
    margin-top:0;
    @media only screen and (max-width: @screen-xs-max) {
      max-height: 2.2em;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height:1.1em;
    }

  }

  .tbl-cell{
    padding:5px;
  }

  .product-list-image-link{
    .square-image(150px);
  }

  .product-small-descr{
    line-height:1.4em;
    max-height: 5*1.4em;
    overflow:hidden;
  }

  .price-cell{
    min-width:100px;
    width:100px;
    text-align:center;
    font-weight:bold;
    vertical-align:middle;

    .price{
      padding:5px;
    }

    .controls-wrapper{
      padding:5px;
    }

    .contact-managers{
      font-weight:normal;
      font-size:0.9em;
      p {
        font-style: italic;
      }
    }
  }
}