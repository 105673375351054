.product-property-table{
  border-collapse: collapse;
  width:100%;

  td{
    padding:5px;
    border:2px solid #ddd;
  }

  .group-header{
    td{
      background:#f5f5f5;
    }
  }
}