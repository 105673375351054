.latest-news{

  .news-list-wrapper{
    vertical-align:middle;
  }

  .news-list{
    list-style:none;
    margin-left:0;
    padding-left:0;
    display:flex;
    justify-content: space-between;
    align-items: stretch;
    @media only screen and (max-width: @screen-xs-min){
      flex-direction: column;
    }
  }

  .news-item{
    width:24%;
    border: 1px solid #EDEDED;
    overflow: hidden;
    @media only screen and (max-width: @screen-xs-min){
      width:100%;
      &:nth-child(3),&:nth-child(4){
        display: none;
      }
      &:nth-child(1){
        margin-bottom:25px;
      }
      img {
        height:initial !important;
        width: 100% !important;
      }
    }

    img{
      height:160px;
    }

    .news-link{
      text-decoration:none;
      color:#666;
      display:inline-block;
      font-size:14px;
      font-weight:400;
      margin: 0 10px 10px 10px;
    }
    .news-date{
      font-size: 14px;
      font-weight: 400;
      color: #c1c1c1;
      margin: 25px 10px 10px 10px;
      display: block;
    }
  }

  .latest-news-all-news-button-wrapper{
    text-align: center;
    padding: 25px 0 45px 0;

    a{
      font-size: 18px;
      text-decoration: none;
      color: #ED1C24;
      border: 1px solid #ED1C24;
      padding: 8px 25px;
      font-weight: 400;
    }
  }


}
