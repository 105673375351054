.product-down-btn{
  width:120px;
  text-decoration:none;
  
}

.product-main{
  margin-bottom:20px;
  .prices-cell{
    text-align:left;
    margin-bottom:20px;
    .prices{
      margin-bottom:20px;
      .price{
        font-size:1.1em;
        &.price-wholesale{
          font-size:1.5em;
        }
      }
    }

    .contact-managers{
      width:250px;
    }

  }

  .product-image{
    width: 170px;
  }
}

.banner-cell{
  width:170px;
  text-align:right;
}

.product-image-wrapper{
  display:inline-block;
  height:150px;
  width:150px;
  overflow:hidden;
  border:2px solid #ddd;
  white-space: nowrap;
  font-size:0;

  &:before{
    display:inline-block;
    height:100%;
    width:0;
    vertical-align:middle;
    content:'';
    //margin-left:px;

  }

  img{
    vertical-align: middle;
    width:100%;

  }
}

.product-info-sections-list{
  margin:0;
  padding:0;
  word-spacing: -5px;
  display:block;

  .section-link{
    display:inline-block;
    width:100%;
    height:100%;
    padding:10px 20px;
    text-decoration:none;
    color:inherit;
    white-space: nowrap;
  }

  .section-switch{
    width:25%;
    list-style:none;
    text-align:center;
    background:linear-gradient(to bottom, #fff, #F1F2F6);
    border: 1px solid #dcdcdc;
    display:inline-block;

    &.current{
      background:#fff;
      border-bottom:none;
    }

    .icon{
      margin-right:10px;
      vertical-align:middle;
    }
    .text{
      vertical-align:middle;
    }
  }
}

.get-partner-price-button{
  margin-top: 4px;
}

.product-info-section{
  margin-top:20px;

  &.disabled{
    display:none;
  }

  &.product-description{
    h2{
    }
  }
}