@popup-width: 600px;
.common-popup{
  display:none;
  padding:10px;

  &.enabled{
    display:block;
    position:fixed;
    width:@popup-width;
    top:20%;
    left:50%;
    margin-left:-@popup-width/2;
    background: #f8f4ff;
    border:2px solid #cccbd0;
    //border-radius:3px;
    box-shadow:0 0 10px 3px rgba(0,0,0,0.3);
    z-index:100;
  }

  .popup-header{
    font-weight:bold;
    font-size:1.5em;
    border-bottom:1px solid #ddd;
  }

  .popup-body{
    margin:10px auto;
    border-bottom:1px solid #ddd;
  }

  .popup-controls{
    text-align:right;
    .btn{
      padding:6px 10px !important;
    }
  }
}