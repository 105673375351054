@icon-font-path: "../../fonts/";

a{
  text-decoration: underline;
}

h2{
  font-size:1.8em;
}

.btn{
  border-radius:0;
  padding: 6px 25px;
}

.btn-danger{
  background: @danger-color;
}

.btn-default{
  background: #eee;
}

.irs-bar{
  background:#ed5565;
  height:5px;
}
.irs-line{
  background:#E1E4E9;
  height:5px;
}

